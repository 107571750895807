<template>
  <div class="flex h-full grow flex-col">
    <G7Header />
    <div class="relative top-[5.625rem] flex grow flex-col lg:static">
      <Navbar class="hidden lg:flex" @click="scroll" />
      <HomeHeroSlider class="mt-4 lg:mt-auto" />

      <div class="m-5 flex items-center justify-between uppercase">
        <Headline
          :h="1"
          size="3XL"
          class="font-black"
          title="Ihr My Go7seas Account"
        />
        <Logout v-if="auth.check.value" class="hidden lg:flex" />
      </div>

      <hr class="border-2 drop-shadow-lg" />

      <main v-if="loading" class="grow py-12">
        <LoadingSpinner />
      </main>

      <main v-show="!loading" class="grow">
        <slot />
      </main>
      <G7Footer />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Ref } from "vue";

await useDiaShow().fetch(apiUrl("/catalog/homepages/mygo7seas/diashow"));

const loading: Ref<boolean> = useLoading(false);
const auth = useAuth();

function scroll() {
  if (useRoute().path === "/einloggen") {
    scrollToIdPosition(
      "loginform",
      window.innerWidth >= 1024 ? 350 : 200,
      "smooth"
    );
  }
}

watch(useRoute(), async () => {
  await useDiaShow().fetch(apiUrl("/catalog/homepages/mygo7seas/diashow"));
});
</script>
