<template>
  <div class="flex cursor-pointer items-center" @click="auth.logout()">
    <div
      class="flex size-8 items-center justify-center rounded-full bg-g7-blue"
    >
      <IconsX class="size-6 fill-white" />
    </div>
    <span class="ml-2 underline">Ausloggen</span>
  </div>
</template>

<script lang="ts" setup>
const auth = useAuth();
</script>
